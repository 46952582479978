<template>
  <div
    class="container-fluid"
    style="padding-right: 0; padding-left: 0"
    data-app
  >
    <div class="title">
      <v-row>
        <h1><strong>SOLICITAR CUENTA</strong></h1>
      </v-row>
      <v-row>
        <h5>
          Utiliza el siguiente formulario para solicitar tu cuenta y un asesor
          comercial se comunicará a la brevedad para un asesoramiento
          personalizado.
        </h5>
      </v-row>
    </div>
    <div>
      <v-row class="form-solicitar">
        <v-col cols="8">
          <v-form ref="form">
            <v-card color="#000" dark elevation="3" outlined>
              <v-card-title style="font-weight: 700"
                >Tus datos de contacto</v-card-title
              >
              <v-card-text>
                <div v-if="loading">
                  <loader
                    object="#4caf50"
                    color1="#ffffff"
                    color2="#17fd3d"
                    size="5"
                    speed="2"
                    bg="#343a40"
                    objectbg="#999793"
                    opacity="40"
                    disableScrolling="false"
                    name="box"
                  ></loader>
                </div>
                <v-container>
                  <v-divider></v-divider>
                  <p>Cliente</p>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="nombre"
                        :rules="namenombre"
                        :counter="30"
                        label="Nombre"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="apellido"
                        :rules="rules_apellido"
                        :counter="30"
                        label="Apellido"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="cuit"
                        :rules="namecuit"
                        :counter="11"
                        label="Número de CUIT"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="razonSocial"
                        :rules="namerazon_social"
                        :counter="30"
                        label="Razón Social"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="email"
                        :rules="nameemail"
                        label="Email"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="telefono"
                        :rules="rules_telefono"
                        label="Teléfono"
                        type="number"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
            <br />
            <v-row>
              <v-col
                cols="12"
                md="12"
                align="center"
                style="padding: 0px !important"
              >
                <v-btn
                  :loading.sync="loading"
                  color="black"
                  class="gradient-button gradient-button-1"
                  @click="enviar_solicitud()"
                >
                  Enviar solicitud
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </div>
    <br />
    <Footer />
  </div>
</template>

<script>
import Footer from "@/views/Layouts/Footer.vue";
import { SolicitudCuenta } from "@/services/solicitud_servicio.js";

export default {
  name: "Solicitud",
  data: () => ({
    dialog: false,
    loading: false,
    error: false,
    errored: false,

    //   respuesta validacion campos
    res: false,
    valid: true,
    //   v-model campos
    nombre: "",
    namenombre: [
      (v) => !!v || "Nombre es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Nombre no debe superar los 30 caracteres",
    ],
    apellido: "",
    rules_apellido: [
      (v) => !!v || "Apellido es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Apellido no debe superar los 30 caracteres",
    ],
    email: "",
    nameemail: [
      (v) => !!v || "EL campo Email es requerido",
      (v) => /.+@.+\..+/.test(v) || "Email no es valido",
    ],

    telefono: "",
    rules_telefono: [
      (v) => !!v || "El campo Teléfono es requerido",
      (v) =>
        (v && v.length <= 15) ||
        "El campo Teléfono no debe superar los 15 caracteres",
    ],

    cuit: "",
    namecuit: [
      (v) => !!v || "EL campo CUIT es requerido",
      (v) =>
        (v && v.length <= 11) ||
        "El campo CUIT no debe superar los 11 caracteres",
    ],

    razonSocial: "",
    namerazon_social: [
      (v) => !!v || "EL campo Razón Social es requerido",
      (v) =>
        (v && v.length <= 30) ||
        "El campo Razón Social no debe superar los 30 caracteres",
    ],
  }),
  components: {
    Footer,
  },

  methods: {
    async enviar_solicitud() {
      var payload = {
        nombre: this.nombre,
        apellido: this.apellido,
        cuit: this.cuit,
        razonsocial: this.razonSocial,
        email: this.email,
        telefono: this.telefono,
      };
      this.validate();

      if (this.res) {
        this.error = false;
        this.loading = true;
        this.dialog = true;
        await SolicitudCuenta.solicitar_cuenta(payload)
          .then((response) => {
            this.$toastr.s(response.data.message);
            this.loading = false;
            this.res = false;
            this.limpiar_campos();
            this.res = this.$refs.form.reset();
          })
          .catch((error) => {
            this.errored = true;
            this.loading = false;
            var data = error.response.data;
            this.error = true;
            this.$toastr.e(data.message);
          })
          .finally(() => (this.loading = false));
      } else {
        this.$toastr.e("Error al enviar formulario, verificar datos de envio");
      }
    },

    validate() {
      this.$refs.form.validate();
      this.res = this.$refs.form.validate();
    },

    intento() {
      this.$router.go();
    },

    limpiar_campos() {
      this.apellido = "";
      this.nombre = "";
      this.cuit = "";
      this.razonSocial = "";
      this.email = "";
      this.telefono = "";
    },
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;900&display=swap");
</style>
<style scoped>
.v-text-field >>> .v-input__slot::before {
  background: linear-gradient(
    to right,
    #00b1eb 13.02%,
    #1db6c8 48.96%,
    #c2d501 85.42%
  );
  padding: 1px;
}
.title {
  font-family: "Montserrat", sans-serif;
  padding: 4%;
  color: black;
}
.form-solicitar {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.row {
  justify-content: center;
}
.v-sheet.v-card {
  border-radius: 24px 24px;
}
h1 {
  font-weight: 900;
}
h3 {
  font-weight: 400;
}
.v-btn:not(.v-btn--round).v-size--default {
  height: auto;
  padding: 15px 15px;
}
.gradient-button {
  color: #000;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  box-shadow: 0 0 20px #eee;
  font-weight: 900;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  display: inline-block;
  border-radius: 6px;
}
.gradient-button:hover {
  background-image: linear-gradient(
    to left,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin: 8px 10px 12px;
}
.gradient-button-1 {
  background-image: linear-gradient(
    to right,
    #c2d501 0%,
    #1db6c8 50.52%,
    #00b1eb 100%
  );
  margin: 8px 10px 12px;
}
.gradient-button-1:hover {
  background-position: right center;
}
@media (max-width: 420px) {
  .v-card__title {
    font-size: large;
  }
  h1 {
    font-size: xx-large;
  }
  h5 {
    padding: 2%;
  }
  .gradient-button {
    font-size: initial;
  }
}
.title {
  margin-top: 0px;
  margin-bottom: 0px;
}
</style>
